import * as styles from "./template-quiz-result.module.scss"

import { Blob1, Blob2, Blob3 } from '../components/blobs'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"
import React, { useState } from "react"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import Button from "../components/button"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from "../components/v2/footer"
import Header from "../components/v2/header"
import InteractEmbed from "../components/utils/interact-embed"
import Modal from "../components/modal"
import SEO from "../components/seo"
import Section from "../components/section"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"

const TemplateQuizResultTemplate = ({ data }) => {
  // Initialize page data
  const { result_slug, result_title, result_industry, result_category } = data.quizResult.data

  const [preview, setPreview] = useState()

  return (
    <>
      <SEO
        title={ `Your ${ result_industry } Quiz Results | Interact Template Quiz` }
        description={ `We chose a few high-converting quiz templates for your ${ result_industry.toLowerCase() } brand. Each template is researched and written by a quiz strategy expert and designed to grow your email list + sell products/services.` }
        template={ true }
        noIndex={ true }
      />
      <Header btnTheme="primary" />
      <main className={ styles.quizResult }>
        <Section theme="white" style={ { marginTop: `4.5rem` } }>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 text-center">
              <h1 className="heading-xl color-black">{ result_title }</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground text-center">We chose a few high-converting quiz templates for your { result_industry.toLowerCase() } brand. Each template is researched and written by a quiz strategy expert and designed to grow your email list + sell products/services.</h2>
              <div className={ styles.description }>
                <ol className="text-body color-blue-75">
                  {
                    data.templates.edges.map(({node}, i) => {
                      const { page_slug, id__from_template_record_: id, title__from_template_record_: title } = node.data
                      return (
                        <li key={ i }><span className={ styles.num }>{ `${ i+1 }` }</span> <AnchorLink to={ `/template-quiz/${ result_slug }/#${ page_slug }` } title={ `${ title }` } /></li>
                      )
                    })
                  }
                </ol>
              </div>
            </div>
          </div>
        </Section>
        {
          data.templates.edges.map(({node}, i) => {
            const { page_slug, main_description, cover_img, id__from_template_record_: id, title__from_template_record_: title, img__from_template_record_: img } = node.data
            return (
              <Section theme="grey" key={ i }>
                <div id={ page_slug } className="row justify-content-center align-items-center">
                  <div className={ `col-lg-5 ${ i%2 === 0 ? 'order-lg-1' : '' } text-md-center` }>
                    <p className="heading-xl color-black">{ `${ title }` }</p>
                    <p className="heading-sm color-muted-foreground">{ main_description }</p>
                    <div className="d-flex" style={ { marginTop: `2rem` } }>
                      <button
                        className={ [styles.preview, 'd-none d-lg-flex align-items-center'].join(' ') }
                        onClick={ () => setPreview(id) }
                        onKeyPress={ (e) => e.code == 'Space' || e.code == 'Enter' ? setPreview(id) : null }
                        role="button"
                        tabIndex={ 0 }>
                        Take the quiz <FontAwesomeIcon icon={ faArrowRight } style={{ marginLeft: `0.75rem` }}/>
                      </button>
                      <a
                        href={ `https://quiz.tryinteract.com/#/preview/template/${ id }` }
                        target="_blank"
                        className={ [styles.preview, 'd-flex d-lg-none align-items-center'].join(' ') }>
                        Take the quiz <FontAwesomeIcon icon={ faArrowRight } style={{ marginLeft: `0.75rem` }}/>
                      </a>
                    </div>
                  </div>
                  <div className={ `col-lg-7 ${ i%2 === 0 ? 'order-lg-0' : '' } pd-top-md-2` }>
                    { cover_img && (
                      <div className={ styles.embedContainer }>
                        <div
                          className={ styles.embed }
                          onClick={ () => setPreview(id) }
                          onKeyPress={ (e) => e.code == 'Space' || e.code == 'Enter' ? setPreview(id) : null }
                          role="button"
                          tabIndex={ 0 }>
                          <GatsbyImage image={ getImage(cover_img.localFiles[0]) } alt={ `"${ title }" quiz template cover page` }/>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Section>
            )
          })
        }

        <Section theme="purple500" style={ { padding: `5rem 0` } }>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-white">Get started today</p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p className="heading-sm color-white text-center" style={{ marginTop: '0', marginBottom: '2rem' }}>Use our AI quiz maker to create a beautiful quiz for your brand in a minute.</p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">Make a quiz - for free</Button>
            </div>
          </div>
        </Section>

        { preview ? (
          <Modal className={ styles.previewModal } isOpen={ true } onClose={ () => setPreview(false) }>
            <div className={ styles.content }>
              <InteractEmbed id={ preview } template={ true } />
            </div>
          </Modal>
        ) : null }
      </main>
      <Footer/>
    </>
  )
}

export default TemplateQuizResultTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    quizResult: airtable(
      table: {eq: "Results"}
      data: {result_slug: {eq: $slug}}
    ) {
      data {
        result_slug
        result_title
        result_industry
        result_category
      }
    },
    templates: allAirtable(
      filter: {table: {eq: "Pages"}, data: {template_quiz_result: {eq: $slug}}}
    ) {
      edges {
        node {
          data {
            page_slug
            main_description
            cover_img {
              localFiles {
                childImageSharp {
                  gatsbyImageData (
                    width: 641,
                    quality: 100,
                  )
                }
              }
            }
            id__from_template_record_
            title__from_template_record_
            img__from_template_record_
          }
        }
      }
    }
  }
`